<template>
  <div>
    <div class="container-fluid center-max-1320-px p-3">
      <div class="col-md-12 d-flex justify-content-center my-5">
        <div class="col-md-8 alert alert-danger" role="alert">
          <h3 class="alert-heading">Payment failed</h3>
          <hr />
          <p>Your payment was rejected.</p>
          <p>Please check if your payment details are correct and try again.</p>
          <router-link id="payment-retry-button" class="alert-link text-uppercase" to="/payment"
            >Try again</router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useQuery } from '@/app/use/router';
export default {
  name: 'FailedPayment',
  inheritAttrs: false,
  setup() {
    const { query, removeQuery } = useQuery();
    const uuid = query.value.uuid;
    removeQuery();
    return { uuid };
  },
};
</script>
