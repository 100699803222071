import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export const useQuery = () => {
  const route = useRoute();
  const router = useRouter();

  const query = computed(() => route.query);
  const search = computed(() => route.query.search);
  const columns = computed(() => route.query.columns);

  const update = queryParam => {
    router.replace({ query: { ...route.query, ...queryParam } }).catch(() => {});
  };

  const removeQuery = () => {
    router.replace({ query: null }).catch(() => {});
  };

  return { search, update, query, columns, removeQuery };
};
